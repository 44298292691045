:root {
  /* Define colors as colors */
  --black: #00;
  --pink: #FFA4D5;
  --purple: #A4A4F5;
  --green: #B3FFC7;
  --grey: #EFE7ED;
  --yellow: #FFE780;
  --blue: #99E2FF;
  --orange: #FFC897;
  --white: #fff;

  /* color intentions */
  --primary-color: var(--white);
  --purple-heading: var(--purple);
  --black-heading: var(--black);
  --pink-heading: var(--pink);
  --navbar-bg: #99E2FF;
  --green-highlight: var(--green);
  --text-color: var(--black);
  --btn-color: var(--pink);
  --btn-bg: var(--white);

  /* radius */
  --input-radius: 8px;
  --border-radius: 8px;

  /* Font Sizes */
  --heading-1: 4rem;
  --heading-2: 3.2rem;
  --heading-3: 2.4rem;
  --body-text: 1.8rem;
  --link-text: 1.4rem;
  --input-text: 1.6rem;
  --small-text: 1.4rem;
  --btn-text: 1.6rem;

  /* Shadows */
  --shadow-1: 0px 1px 3px 0px rgba(47, 50, 125, 0.1),
    0px 1px 2px 0 rgba(47, 50, 125, 0.06);

  --shadow-2: 0px 4px 6px -1px rgba(47, 50, 125, 0.1),
    0px 2px 4px -1px rgba(47, 50, 125, 0.06);

  --shadow-3: 0px 12px 27px -3px rgba(47, 50, 125, 0.1),
    0px 4px 6px -2px rgba(4, 20, 22, 0.06);

  /* Radius */
  --card-radius: 12px;
}

@font-face {
  font-family: "ComicSans";
  src: url("../../assets/fonts/comic-sans.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Obitron";
  src: url("../../assets/fonts/Orbitron-Regular.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Obitron";
  src: url("../../assets/fonts/Orbitron-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  scroll-behavior: smooth;
  // background-color: white;
  // color: black;
}

body {
  font-family: 'ComicSans', sans-serif;
  background-color: var(--primary-color);
  color: var(--text-color);
  font-size: var(--body-text);
  font-weight: 400;
  line-height: 1.6;
}

h1,
h2,
h3 {
  line-height: 1.3;
}

h1 {
  font-size: var(--heading-1);

  @include respond(tablet-lg) {
    --heading-1: 4.8rem;
    font-size: var(--heading-1);
  }
}

h2 {
  font-size: var(--heading-2);
  @include respond(tablet-lg) {
    --heading-2: 3.8rem;
    font-size: var(--heading-2);
  }
}

h3 {
  font-size: var(--heading-3);
}

p {
  font-size: var(--body-text);
  margin-bottom: 16px;
  font-weight: 400;
}

a {
  font-size: var(--link-text);
  font-weight: 400;
  color: var(--link-color);
  text-decoration: none;
}

button {
  appearance: none;
  background-color: var(--btn-bg);
  border-radius: var(--border-radius);
  border: none;
  color: var(--btn-color);
  font-size: var(--btn-text);
  font-weight: 500;
  padding: 1.2rem 2.4rem;
  transition: box-shadow 0.3s ease-in;

  &:hover {
    cursor: pointer;
  }
}
