.stack {
  display: flex;
  flex-direction: column;
}
#btn2 {
  color: red;
}

.Navbar {
  width: 100%;
  background: var(--navbar-bg);
  background: url('../../../public/img/navbar-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  z-index: 100;
  // top: 0;

  @include respond(tablet-lg) {
    background: transparent;
    position: relative;

  }


  &__inner {
    max-width: 1200px;
    min-height: 64px;
    padding: 0 24px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

	&__logo {
    width: 130px;
    height: auto;
    margin-top: 16px;

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  & button {
    padding: 0;
    color: green;

    @include respond(tablet-lg) {
      display: none;
    }
  }

	&__menu-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

	&__menu-link {
    display: inline-block !important;
		font-size: 1.8rem;

    &:not(:last-child) {
      margin-right: 3.2rem;
    }
  }

	&__links {
    display: none;

    @include respond(tablet-lg) {
      display: block;
    }
  }


  .MobileNavbar {
    width: 100%;
    height: calc(100vh - 60px);
    z-index: 5000;
    background-color: var(--navbar-bg);

    &__links {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      z-index: 10;
      margin: auto;
      padding-top: 8rem;
      padding-bottom: 8rem;
      text-align: center;
    }

    &__menuLink {
      font-size: 16px;
      margin-bottom: 24px;

      & a {
        --link-text: 1.8rem;
        font-size: var(--link-text);
      }
    }

    &__social-links {
      display: flex;
      justify-content: center;

      & a {

        &:not(:last-child) {
         margin-right: 16px; 
        }
      }
    }
  }
}
